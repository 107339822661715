/**
 * All global frontend scripts required should be included here.
 *
 * This is the file that Webpack is compiling into main.[hash].js
 */

import "../../pubstack/assets/tvguide"; // load before footer css
import "./sass/footer.scss";

import expandedMenu from "./js/expanded-menu"; // 2KB
import EmailPreferences from "./js/newsletter/preferences";
import EmailUnsubscribe from "./js/newsletter/unsubscribe";
import SubscribeModal from "./js/newsletter/modal";
import NewsletterBlock from "./js/newsletter/block";
import "sticky-kit/dist/sticky-kit.js"; // 3KB
import Analytics from "../../pubstack/assets/js/utils/analytics"; // 4KB
import "./js/blocks/event-upcoming-experiences";
import "./js/blocks/event-vod-promo";

let analyticsInstance = new Analytics();
require("./js/ads/cordial-footer");

import articleOembed from "./js/article/article-oembed";
import Footer from "./js/footer";
import ofTheEssenceArchive from "./js/of-the-essence-archive";
import nativeTout from "./js/native-tout";

require("../../pubstack/assets/js/ads/cls");

import VideoJw from "../../pubstack/assets/js/video-jw";
import IasVideo from "../../pubstack/assets/js/ads/ias-video";
import PlaylistPlayer from "./js/blocks/playlist-player";
import PlaylistModal from "./js/blocks/playlist-schedule";
new IasVideo();

$(function () {
	// Click on Viewmore Button Push DatLayer Event - Start
	jQuery(document).ready(function () {
		jQuery(".viewmore").on("click", function (e) {
			e.preventDefault();
			var viewmoreCount = $(this).attr("data-counter");
			dataLayer.push({
				event: "analytics:iscroll:ga", // GA4
				eventCategory: "iscroll",
				eventAction: "click_homepage", // GA4
				eventLabel: viewmoreCount - 1, // GA4
			});
		});
	});
	// Click on Viewmore Button Push DatLayer Event - End

	setTimeout(function () {
		analyticsInstance.init();
		new VideoJw().init();
		Footer.init();
		ofTheEssenceArchive.init();
		nativeTout.init();
		expandedMenu();
		articleOembed();

		// Newsletter Subscribe
		PlaylistModal.init();
		EmailPreferences.init();
		SubscribeModal.init();
		EmailUnsubscribe.init();
		NewsletterBlock.init();
	}, 0);

	// Event listener for SVG icon click
	jQuery(".arrow-icon").on("click", function () {
		jQuery(".essence-livesearch-form").submit(); // Submit the form when SVG icon is clicked
	});

	jQuery(".essence-search-input").on("click input", function () {
		var searchText = $(this).val().trim();
		var parentDiv = $(this).closest(".essence-search-form-container");
		if (searchText) {
			parentDiv.addClass("active"); // Add active class if search field has text
			jQuery(".essence-live-search-results").show();
		} else {
			parentDiv.removeClass("active"); // Remove active class if search field is empty
		}
	});

	jQuery(".essence-search-form-container").focusout(function () {
		jQuery(".essence-live-search-results").css({
			display: "none",
		});
		jQuery(".essence-search-form-container").removeClass("active");
	});
	PlaylistPlayer();

	// Live search ajax
	jQuery(".essence-search-input").on("input", function () {
		var searchText = $(this).val().trim().toLowerCase();
		jQuery.ajax({
			url: `${pubstack.ajaxUrl}`,
			type: "POST",
			data: {
				action: "essence-live-search",
				search_text: searchText,
			},
			success: function (response) {
				// Handle the response from the server
				jQuery(".essence-live-search-results").html(
					response === "not_found" ? "" : response
				);
			},
			error: function (xhr, status, error) {
				// Handle errors
				console.error("Error:", error);
			},
		});
	});
	// Live search ajax end
});

document.addEventListener("DOMContentLoaded", function () {
	var loaderWrapper = document.querySelector(".loader-wrapper");
	if (loaderWrapper) {
		// Hide the loader and display the content after 3 seconds
		setTimeout(function () {
			document.querySelector(".loader-wrapper").classList.add("hidden");
			document.getElementById("content").classList.add("essence-fade-in");
		}, 1000);
	}
});
