import { doSubscribeAjax, isFormValid } from "./util";

function Preferences() {
	this.$form = $("#email-preferences .subscribe-form");
	this.$checkbox = this.$form.find("#terms_agree");
    this.$subscribeButton = this.$form.find("button[type='submit']");
	this.buttonId = this.$subscribeButton.attr('id');

	this.init = () => {
		if (this.$form.length) {
			this.register();
			this.handleCheckbox();
		}
	};

	this.handleCheckbox = () => {
		if (this.buttonId !== "submit-icon") {
			this.$subscribeButton.prop('disabled', !this.$checkbox.is(':checked'));

			this.$checkbox.on('change', () => {
				this.$subscribeButton.prop('disabled', !this.$checkbox.is(':checked'));
			});
		}
    };

	this.register = () => {
		this.$form.on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();

		if (isFormValid(this.$form, this.buttonId !== "submit-icon")) {

			doSubscribeAjax(this.$form, null, null, () => {
				if (!this.$form.data("redirect")) {
					$("html, body").animate({ scrollTop: 0 }, 500);
				}
			});
		}
	};
}

export default new Preferences();
