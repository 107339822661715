function Footer() {
	this.selectors = {
		footer: document.querySelector(".site-footer"),
		footerMagenta: document.querySelector(".footer-magenta"),
		footerFixed: document.querySelector(".footer-fixed"),
		content: document.querySelector(".site-content"),
	};

	this.init = () => {
		if (!document.querySelector(".site-footer")) {
			return;
		}
		this.observeFooter();
		this.observeFooterFixed();
	};

	this.observeFooter = () => {
		const footerObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					// Stop JWPlayer floating video before user scrolls to the footer.
					this.selectors.content.style.transform = entry.isIntersecting
						? "translate(0)"
						: "";
				});
			},
			{ threshold: 0 }
		);

		footerObserver.observe(this.selectors.footer);
	};

	this.observeFooterFixed = () => {
		if (this.selectors.footerFixed) {
			const footerFixedObserver = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((entry) => {
						if (entry.boundingClientRect.top < window.innerHeight) {
							// Fixed footer visibility on scroll.
							this.selectors.footerFixed.style.display = entry.isIntersecting
								? "none"
								: "block";
						}
					});
				},
				{ threshold: 0, rootMargin: "-100% 0% 0% 0%" }
			);

			footerFixedObserver.observe(this.selectors.footerMagenta);
		}
	};
}

export default new Footer();

window.addEventListener('scroll', function() {
	var header = document.querySelector('.site-branding');
	var sticky = header.offsetTop;

	if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
    }
});

jQuery(document).ready(function($) {
    // Handle hover event
	let overrideurl = $('.essenc-category-list-section').find('.image-container a').attr('href');

    $('body').on('mouseenter', '.essence-category-list li', function() {
        var thumbnailUrl = $(this).find('a').attr('data-thumbnail');
		var title = $(this).find('a').attr('data-title');
		if(thumbnailUrl!='') {
			setTimeout(function() {
				$('.essence-category-image').parent().attr('href', '');
				$('.essence-category-image').attr('src',thumbnailUrl );
				$('.image-container-text').text(title);
			}, 700);
		}
    });

    // Optional: Handle hover out event if needed
    $('body').on('mouseleave', '.essence-category-list li', function() {
		var originalUrl =  jQuery('.essence-category-image').attr('data-original-image');
		var title =  jQuery('.essence-category-image').attr('data-title');
        setTimeout(function() {
			$('.essence-category-image').parent().attr('href', overrideurl);
            $('.essence-category-image').attr('src', originalUrl);
			$('.image-container-text').text(title);
        }, 700);
    });

	//Shop block scrollclick right and left
	const $scrollContainer = $('.essence_shop_block');
    const scrollAmount = 300; // Adjust this value to control scroll amount

    // Ensure the scroll container and buttons exist before adding event handlers
    if ($scrollContainer.length && $('.arrow-left').length && $('.arrow-right').length) {

        // Handle left arrow click
        $('.arrow-left').on('click', function() {
            $scrollContainer.animate({
                scrollLeft: $scrollContainer.scrollLeft() - scrollAmount
            }, 300); // Adjust duration as needed
        });

        // Handle right arrow click
        $('.arrow-right').on('click', function() {
            $scrollContainer.animate({
                scrollLeft: $scrollContainer.scrollLeft() + scrollAmount
            }, 300); // Adjust duration as needed
        });
    }
});
